var pre = '/dashboard/';
export default {
  path: '/dashboard',
  title: 'Dashboard',
  header: 'home',
  custom: 'i-icon-demo i-icon-demo-dashboard',
  children: [{
    path: "".concat(pre, "console"),
    title: '主控台'
  }, {
    path: "".concat(pre, "monitor"),
    title: '监控页'
  }, {
    path: "".concat(pre, "workplace"),
    title: '工作台',
    subtitle: 'Workplace'
  }]
};