function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapGetters, mapActions } from 'vuex';
import menuSider from '@/menu/sider';
import tTitle from '../mixins/translate-title';
import Setting from '@/setting';
import { getAllSiderMenu } from '@/libs/system';
import { cloneDeep } from 'lodash';
import _random from '@/libs/random_str';
export default {
  name: 'iTabs',
  mixins: [tTitle],
  computed: _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapState('admin/page', ['opened', 'current'])), mapState('admin/layout', ['showTabsIcon', 'tabsFix', 'tabsReload', 'tabsOrder', 'headerFix', 'headerStick', 'isMobile', 'menuCollapse'])), mapGetters('admin/menu', ['hideSider'])), {}, {
    classes: function classes() {
      return {
        'i-layout-tabs-fix': this.tabsFix
      };
    },
    isHeaderStick: function isHeaderStick() {
      return this.hideSider;
    },
    styles: function styles() {
      var style = {};

      if (this.tabsFix && !this.headerFix) {
        style.top = "".concat(64 - this.scrollTop, "px");
      }

      var menuWidth = this.isHeaderStick ? 0 : this.menuCollapse ? 80 : Setting.menuSideWidth;

      if (!this.isMobile && this.tabsFix) {
        style.width = "calc(100% - ".concat(menuWidth, "px)");
        style.left = "".concat(menuWidth, "px");
      }

      return style;
    }
  }),
  data: function data() {
    return {
      // 得到所有侧边菜单，并转为平级，查询图标用
      allSiderMenu: getAllSiderMenu(menuSider),
      scrollTop: 0
    };
  },
  methods: _objectSpread(_objectSpread({}, mapActions('admin/page', ['close', 'closeLeft', 'closeRight', 'closeOther', 'closeAll', 'updateOpened'])), {}, {
    tabLabel: function tabLabel(h, page) {
      var title = h('span', this.tTitle(page.meta.title) || '未命名');
      var slot = [];

      if (this.showTabsIcon) {
        var fullPathWithoutQuery = page.fullPath.indexOf('?') >= 0 ? page.fullPath.split('?')[0] : page.fullPath;
        var currentMenu = this.allSiderMenu.find(function (menu) {
          return menu.path === fullPathWithoutQuery;
        }) || {};
        var icon;

        if (currentMenu.icon) {
          icon = h('Icon', {
            props: {
              type: currentMenu.icon
            }
          });
        } else if (currentMenu.custom) {
          icon = h('Icon', {
            props: {
              custom: currentMenu.custom
            }
          });
        } else if (currentMenu.img) {
          icon = h('img', {
            attrs: {
              src: currentMenu.img
            }
          });
        }

        if (icon) slot.push(icon);
        slot.push(title);
      } else {
        slot.push(title);
      }

      return h('div', {
        class: 'i-layout-tabs-title'
      }, slot);
    },
    handleClickTab: function handleClickTab(tabName) {
      if (tabName === this.current) {
        if (this.tabsReload) {
          this.$emit('on-reload');
        }
      } else {
        var page = this.opened.find(function (page) {
          return page.fullPath === tabName;
        });
        var name = page.name,
            params = page.params,
            query = page.query;
        if (page) this.$router.push({
          name: name,
          params: params,
          query: query
        }, function () {});
      }
    },
    handleClickClose: function handleClickClose(tagName) {
      this.close({
        tagName: tagName
      });
    },
    handleScroll: function handleScroll() {
      if (this.tabsFix && !this.headerFix) {
        var scrollTop = document.body.scrollTop + document.documentElement.scrollTop;
        this.scrollTop = scrollTop > 64 ? 64 : scrollTop;
      }
    },
    handleClose: function handleClose(name) {
      var params = {
        pageSelect: this.current
      };

      switch (name) {
        case 'left':
          this.closeLeft(params);
          break;

        case 'right':
          this.closeRight(params);
          break;

        case 'other':
          this.closeOther(params);
          break;

        case 'all':
          this.closeAll();
          break;
      }
    },
    handleDragDrop: function handleDragDrop(name, newName, a, b) {
      var opened = cloneDeep(this.opened);
      opened.splice.apply(opened, [b, 1].concat(_toConsumableArray(opened.splice(a, 1, opened[b]))));
      this.updateOpened({
        opened: opened
      });
    },
    random: function random() {
      return _random(6);
    }
  }),
  mounted: function mounted() {
    document.addEventListener('scroll', this.handleScroll, {
      passive: true
    });
    this.handleScroll();
  },
  beforeDestroy: function beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll);
  }
};