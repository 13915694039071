var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "i-layout-menu-side-title",
      class: { "i-layout-menu-side-title-with-collapse": _vm.collapse }
    },
    [
      _vm.withIcon
        ? _c(
            "span",
            {
              staticClass: "i-layout-menu-side-title-icon",
              class: { "i-layout-menu-side-title-icon-single": _vm.hideTitle }
            },
            [
              _vm.menu.icon
                ? _c("Icon", { attrs: { type: _vm.menu.icon } })
                : _vm.menu.custom
                ? _c("Icon", { attrs: { custom: _vm.menu.custom } })
                : _vm.menu.img
                ? _c("img", { attrs: { src: _vm.menu.img } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.hideTitle
        ? _c(
            "span",
            {
              staticClass: "i-layout-menu-side-title-text",
              class: {
                "i-layout-menu-side-title-text-selected": _vm.selected,
                "i-layout-menu-side-title-text-with-subtitle":
                  _vm.menu.subtitle,
                "i-layout-menu-side-title-text-with-icon": _vm.withIcon
              }
            },
            [
              _vm._v(_vm._s(_vm.tTitle(_vm.menu.title))),
              _vm.menu.subtitle
                ? _c("em", [_vm._v(_vm._s(_vm.tTitle(_vm.menu.subtitle)))])
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }