var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "i-link",
    {
      staticClass: "i-layout-header-logo",
      class: { "i-layout-header-logo-stick": !_vm.isMobile },
      attrs: { to: "/" }
    },
    [
      _vm.isMobile
        ? _c("img", {
            attrs: { src: require("@/assets/images/logo-small.png") }
          })
        : _vm.headerTheme === "light"
        ? _c("img", { attrs: { src: require("@/assets/images/logo.png") } })
        : _c("img", {
            attrs: { src: require("@/assets/images/logo-dark.png") }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }