import request from '@/plugins/request';
export function openVerifyCode() {
  return request({
    url: '/erp/verifyCode/openVerifyCode',
    method: 'get'
  });
}
export function AccountLogin(data) {
  return request({
    url: '/erp/login',
    method: 'post',
    data: data
  });
}
export function checkLoginStatus() {
  return request({
    url: '/erp/loginStatus/check',
    method: 'get'
  });
}
export function doLogout() {
  return request({
    url: '/erp/logout',
    method: 'post'
  });
}