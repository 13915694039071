function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'cadmin-';
export default {
  path: '/cadmin',
  name: 'cadmin',
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'game/jigsaw',
    name: "".concat(pre, "game-jigsaw"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '拼图管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/game/jigsaw');
    }
  }, {
    path: 'game/jigsaw/user',
    name: "".concat(pre, "game-jigsaw-user"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '拼图用户'
    }),
    component: function component() {
      return import('@/pages/cadmin/game/jigsaw/user.vue');
    }
  }, {
    path: 'game/roulette',
    name: "".concat(pre, "game-roulette"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '抽奖管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/game/roulette');
    }
  }, {
    path: 'goods/skuAttr',
    name: "".concat(pre, "goods-skuAttr"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'SKU属性管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/goods/skuAttr');
    }
  }, {
    path: 'goods/spu',
    name: "".concat(pre, "goods-spu"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: 'SPU管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/goods/spu');
    }
  }, {
    path: 'goods/spu/detail',
    name: "".concat(pre, "goods-spu-detail"),
    meta: {
      auth: false,
      title: 'SPU商品详情'
    },
    component: function component() {
      return import('@/pages/cadmin/goods/spu/detail');
    }
  }, {
    path: 'user/list',
    name: "".concat(pre, "user-list"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '注册用户列表'
    }),
    component: function component() {
      return import('@/pages/cadmin/user/list');
    }
  }, {
    path: 'user/highLevel',
    name: "".concat(pre, "user-highLevel"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '高阶管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/user/high-level');
    }
  }, {
    path: 'order/list',
    name: "".concat(pre, "order-list"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '订单管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/order/list');
    }
  }, {
    path: 'home/news',
    name: "".concat(pre, "news-list"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '资讯管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/home/news');
    }
  }, {
    path: 'coupon/batch',
    name: "".concat(pre, "batch-list"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '卡券批次管理'
    }),
    component: function component() {
      return import('@/pages/cadmin/coupon/batch');
    }
  }, {
    path: 'integral',
    name: "".concat(pre, "integral"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '积分规则配置'
    }),
    component: function component() {
      return import('@/pages/cadmin/integral');
    }
  }]
};