//
//
//
//
//
var noMarginClassName = 'i-layout-content-main-no-margin';
var noCopyrightClassName = 'i-copyright-hidden';
var contextFlexClassName = 'i-layout-content-flex';
var contextMainFlexClassName = 'i-layout-content-main-flex';
export default {
  name: 'i-frame',
  props: {
    src: {
      type: String,
      default: ''
    },
    // 是否保留默认左右边距（24px）
    margin: {
      type: Boolean,
      default: false
    },
    // 是否显示底部版权
    copyright: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      ready: false,
      $content: null,
      $copyright: null,
      $layoutContent: null,
      $layoutContentMain: null
    };
  },
  watch: {
    margin: {
      handler: function handler(val) {
        var _this = this;

        this.$nextTick(function () {
          if (_this.$content) {
            if (!val) {
              _this.$content.classList.add(noMarginClassName);
            } else {
              _this.$content.classList.remove(noMarginClassName);
            }
          }
        });
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.$content = document.getElementsByClassName('i-layout-content-main')[0];
    this.$copyright = document.getElementsByClassName('i-copyright')[0];
    if (this.$copyright && !this.copyright) this.$copyright.classList.add(noCopyrightClassName);
    this.$layoutContent = document.getElementsByClassName('i-layout-content')[0];
    if (this.$layoutContent) this.$layoutContent.classList.add(contextFlexClassName);
    this.$layoutContentMain = document.getElementsByClassName('i-layout-content-main')[0];
    if (this.$layoutContentMain) this.$layoutContentMain.classList.add(contextMainFlexClassName);
    this.$nextTick(function () {
      _this2.ready = true;
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (this.$content) this.$content.classList.remove(noMarginClassName);
    if (this.$copyright) this.$copyright.classList.remove(noCopyrightClassName);
    if (this.$layoutContent) this.$layoutContent.classList.remove(contextFlexClassName);
    if (this.$layoutContentMain) this.$layoutContentMain.classList.remove(contextMainFlexClassName);
    this.$content = null;
    this.$copyright = null;
    this.$layoutContent = null;
    this.$layoutContentMain = null;
  }
};