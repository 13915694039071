function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'wechat-';
export default {
  path: '/wechat',
  name: 'wechat',
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'weapp/sale/qr',
    name: "".concat(pre, "weapp-sale-qr"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '销售端小程序 二维码管理'
    }),
    component: function component() {
      return import('@/pages/wechat/weapp/sale/qr');
    }
  }, {
    path: 'weapp/client/qr',
    name: "".concat(pre, "weapp-client-qr"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '用户端小程序 二维码管理'
    }),
    component: function component() {
      return import('@/pages/wechat/weapp/client/qr');
    }
  }, {
    path: 'mp/material',
    name: "".concat(pre, "mp-material"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '公众号素材管理'
    }),
    component: function component() {
      return import('@/pages/wechat/mp/material/list');
    }
  }, {
    path: 'mp/menu',
    name: "".concat(pre, "mp-menu"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '公众号菜单管理'
    }),
    component: function component() {
      return import('@/pages/wechat/mp/menu/list');
    }
  }, {
    path: 'mp/subCallback',
    name: "".concat(pre, "mp-subCallback"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '关注后回复'
    }),
    component: function component() {
      return import('@/pages/wechat/mp/subCallback/index');
    }
  }, {
    path: 'mp/keyCallback',
    name: "".concat(pre, "mp-keyCallback"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '关键词回复'
    }),
    component: function component() {
      return import('@/pages/wechat/mp/keyCallback/list');
    }
  }, {
    path: 'mp/sceneMaterial',
    name: "".concat(pre, "mp-sceneMaterial"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '特定素材管理'
    }),
    component: function component() {
      return import('@/pages/wechat/mp/sceneMaterial/index');
    }
  }]
};