function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'custom-';
export default {
  path: '/custom',
  name: 'custom',
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'ins',
    name: "".concat(pre, "ins"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '客户列表'
    }),
    component: function component() {
      return import('@/pages/custom/ins/list');
    }
  }, {
    path: 'insList',
    name: "".concat(pre, "insList"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '美肌站点'
    }),
    component: function component() {
      return import('@/pages/custom/ins/ins');
    }
  }, {
    path: 'insContract',
    name: "".concat(pre, "insContract"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '美肌站点会员管理'
    }),
    component: function component() {
      return import('@/pages/custom/ins/insContract');
    }
  }, {
    path: 'contract/info',
    name: "".concat(pre, "contract-info"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '合同列表'
    }),
    component: function component() {
      return import('@/pages/custom/contract/list');
    }
  }, {
    path: 'contract/template',
    name: "".concat(pre, "contract-template"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '合同模版'
    }),
    component: function component() {
      return import('@/pages/custom/contract/template');
    }
  }, {
    path: 'letter',
    name: "".concat(pre, "letter"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '意向书列表'
    }),
    component: function component() {
      return import('@/pages/custom/letter/list');
    }
  }, {
    path: 'order',
    name: "".concat(pre, "order"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '订单列表'
    }),
    component: function component() {
      return import('@/pages/custom/order/list');
    }
  }, {
    path: 'invoice',
    name: "".concat(pre, "invoice"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '开票申请审核'
    }),
    component: function component() {
      return import('@/pages/custom/order/invoice');
    }
  }, {
    path: 'order/back',
    name: "".concat(pre, "order-back"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '退货订单'
    }),
    component: function component() {
      return import('@/pages/custom/back/list');
    }
  }, {
    path: 'orderInvoice',
    name: "".concat(pre, "orderInvoice"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '开票申请管理'
    }),
    component: function component() {
      return import('@/pages/custom/order/orderInvoice');
    }
  }, {
    path: 'orderQ3',
    name: "".concat(pre, "orderQ3"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '2023-Q3审核列表'
    }),
    component: function component() {
      return import('@/pages/custom/order/orderQ3');
    }
  }, {
    path: 'offline',
    name: "".concat(pre, "offline"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '支付凭证',
      cache: true
    }),
    component: function component() {
      return import('@/pages/custom/offline/list');
    }
  }, {
    path: 'offline/sub',
    name: "".concat(pre, "offline-sub"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '合并支付凭证',
      cache: true
    }),
    component: function component() {
      return import('@/pages/custom/offline/sub');
    }
  }, {
    path: 'offline/lockLog',
    name: "".concat(pre, "offline-lock-log"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '解锁码日志'
    }),
    component: function component() {
      return import('@/pages/custom/offline/lockLog');
    }
  }, {
    path: 'qrCode',
    name: "".concat(pre, "qrCode"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '二维码批次'
    }),
    component: function component() {
      return import('@/pages/custom/qrCode/list');
    }
  }, {
    path: 'device/lock',
    name: "".concat(pre, "device_lock"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '锁机码管理'
    }),
    component: function component() {
      return import('@/pages/custom/device/list');
    }
  }, {
    path: 'device/sequence',
    name: "".concat(pre, "device_sequence"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '序列号管理'
    }),
    component: function component() {
      return import('@/pages/custom/device/sequence');
    }
  }, {
    path: 'visit',
    name: "".concat(pre, "visit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '机构拜访记录'
    }),
    component: function component() {
      return import('@/pages/custom/visit');
    }
  }, {
    path: 'payWait',
    name: "".concat(pre, "payWait"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '短信催款列表'
    }),
    component: function component() {
      return import('@/pages/custom/order/payWait');
    }
  }, {
    path: 'audit',
    name: "".concat(pre, "audit"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '客户申请审核'
    }),
    component: function component() {
      return import('@/pages/custom/audit');
    }
  }, {
    path: 'writeOffVirtual',
    name: "".concat(pre, "writeOffVirtual"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '核销列表'
    }),
    component: function component() {
      return import('@/pages/custom/ins/writeOffVirtual');
    }
  }]
};