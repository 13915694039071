var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "i-layout-tabs", class: _vm.classes, style: _vm.styles },
    [
      _c(
        "div",
        { staticClass: "i-layout-tabs-main" },
        [
          _c(
            "Tabs",
            {
              attrs: {
                type: "card",
                value: _vm.current,
                animated: false,
                closable: "",
                draggable: _vm.tabsOrder
              },
              on: {
                "on-click": _vm.handleClickTab,
                "on-tab-remove": _vm.handleClickClose,
                "on-drag-drop": _vm.handleDragDrop
              }
            },
            _vm._l(_vm.opened, function(page) {
              return _c("TabPane", {
                key: _vm.random(),
                attrs: {
                  label: function(h) {
                    return _vm.tabLabel(h, page)
                  },
                  name: page.fullPath,
                  closable: page.meta && page.meta.closable
                }
              })
            }),
            1
          ),
          _c(
            "Dropdown",
            {
              staticClass: "i-layout-tabs-close",
              on: { "on-click": _vm.handleClose }
            },
            [
              _c(
                "div",
                { staticClass: "i-layout-tabs-close-main" },
                [_c("Icon", { attrs: { type: "ios-arrow-down" } })],
                1
              ),
              _c(
                "DropdownMenu",
                { attrs: { slot: "list" }, slot: "list" },
                [
                  _c(
                    "DropdownItem",
                    { attrs: { name: "left" } },
                    [
                      _c("Icon", { attrs: { type: "md-arrow-back" } }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("basicLayout.tabs.left")) +
                          "\n                "
                      )
                    ],
                    1
                  ),
                  _c(
                    "DropdownItem",
                    { attrs: { name: "right" } },
                    [
                      _c("Icon", { attrs: { type: "md-arrow-forward" } }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("basicLayout.tabs.right")) +
                          "\n                "
                      )
                    ],
                    1
                  ),
                  _c(
                    "DropdownItem",
                    { attrs: { name: "other" } },
                    [
                      _c("Icon", { attrs: { type: "md-close" } }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("basicLayout.tabs.other")) +
                          "\n                "
                      )
                    ],
                    1
                  ),
                  _c(
                    "DropdownItem",
                    { attrs: { name: "all" } },
                    [
                      _c("Icon", { attrs: { type: "md-close-circle" } }),
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("basicLayout.tabs.all")) +
                          "\n                "
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }