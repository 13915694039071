function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: 'iHeaderNotice',
  data: function data() {
    return {
      badgeProps: {
        offset: [20, 0]
      },
      messageBaseList: [{
        icon: 'md-mail',
        iconColor: '#3391e5',
        title: '蒂姆·库克回复了你的邮件',
        read: 1,
        time: 1557297198
      }, {
        icon: 'md-home',
        iconColor: '#87d068',
        title: '乔纳森·伊夫邀请你参加会议',
        read: 0,
        time: 1557297198
      }, {
        icon: 'md-information',
        iconColor: '#fe5c57',
        title: '斯蒂夫·沃兹尼亚克已批准了你的休假申请',
        read: 1,
        time: 1557297198
      }, {
        icon: 'md-star',
        iconColor: '#ff9900',
        title: '史蒂夫·乔布斯收藏了你的文章',
        read: 1,
        time: 1557297198
      }, {
        icon: 'md-people',
        iconColor: '#f06292',
        title: '比尔·费尔南德斯通过了你的好友申请',
        read: 1,
        time: 1557297198
      }],
      followBaseList: [{
        avatar: 'https://dev-file.iviewui.com/BbnuuEiM0QXNPHVCvb3E2AFrawIjCkqW/avatar',
        title: '史蒂夫·乔布斯 关注了你',
        read: 1,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/zhj85zgAfEjChCNIKT1LQENUIOyOYCaX/avatar',
        title: '斯蒂夫·沃兹尼亚克 关注了你',
        read: 1,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/TkH54UozsINlex15TAMI00GElsfsKSiC/avatar',
        title: '乔纳森·伊夫 关注了你',
        read: 0,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/xrzbBR99F6tYsDJPLNrvwhllowbuL7Gw/avatar',
        title: '蒂姆·库克 关注了你',
        read: 1,
        time: 1557299299
      }, {
        avatar: 'https://dev-file.iviewui.com/bgrngoUb9A6UQ2kAwBFtnSNzhrh2qj1O/avatar',
        title: '比尔·费尔南德斯 关注了你',
        read: 1,
        time: 1557299299
      }],
      todoBaseList: [{
        title: '2019 下半年 OKR',
        content: '需要在 2019-06-14 之前完成',
        tag: '未开始',
        tagProps: {
          color: 'default'
        },
        read: 1
      }, {
        title: '公孙离的面试评审',
        content: '需要在 2019-06-14 之前完成',
        tag: '即将到期',
        tagProps: {
          color: 'red'
        },
        read: 0
      }, {
        title: 'iView 三周年生日会',
        content: '需要在 2019-07-28 之前完成',
        tag: '进行中',
        tagProps: {
          color: 'blue'
        },
        read: 1
      }, {
        title: 'iView 三周年生日会',
        content: '需要在 2019-07-28 之前完成',
        tag: '进行中',
        tagProps: {
          color: 'blue'
        },
        read: 1
      }],
      messageList: [],
      followList: [],
      todoList: [],
      messageLoading: false,
      followLoading: false,
      todoLoading: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('admin/layout', ['isMobile'])), {}, {
    unreadMessage: function unreadMessage() {
      var unread = 0;
      this.messageList.forEach(function (item) {
        if (!item.read) unread++;
      });
      return unread;
    },
    unreadFollow: function unreadFollow() {
      var unread = 0;
      this.followList.forEach(function (item) {
        if (!item.read) unread++;
      });
      return unread;
    },
    unreadTodo: function unreadTodo() {
      var unread = 0;
      this.todoList.forEach(function (item) {
        if (!item.read) unread++;
      });
      return unread;
    }
  }),
  methods: {
    handleLoadMore: function handleLoadMore(tab) {
      this.loadMore(tab.name);
    },
    loadMore: function loadMore(type) {
      var _this = this;

      if (this["".concat(type, "Loading")]) return;
      this["".concat(type, "Loading")] = true;
      setTimeout(function () {
        _this["".concat(type, "List")] = _this["".concat(type, "List")].concat(_toConsumableArray(_this["".concat(type, "BaseList")]));
        _this["".concat(type, "Loading")] = false;
      }, 1000);
    },
    handleClear: function handleClear(tab) {
      this.clearUnread(tab.name);
    },
    clearUnread: function clearUnread(type) {
      this["".concat(type, "List")] = this["".concat(type, "List")].map(function (item) {
        item.read = 1;
        return item;
      });
    }
  },
  mounted: function mounted() {
    this.messageList = _toConsumableArray(this.messageBaseList);
    this.followList = _toConsumableArray(this.followBaseList);
    this.todoList = _toConsumableArray(this.todoBaseList);
  }
};