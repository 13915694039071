import cookies from './util.cookies';
import log from './util.log';
import db from './util.db';
import Setting from '@/setting';
var util = {
  cookies: cookies,
  log: log,
  db: db
};

function tTitle() {
  var title = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (window && window.$t) {
    if (title.indexOf('$t:') === 0) {
      return window.$t(title.split('$t:')[1]);
    } else {
      return title;
    }
  } else {
    return title;
  }
}
/**
 * @description 更改标题
 * @param {Object} title 标题
 * @param {Object} count 未读消息数提示（可视情况选择使用或不使用）
 */


util.title = function (_ref) {
  var title = _ref.title,
      count = _ref.count;
  title = tTitle(title);
  var fullTitle = title ? "".concat(title, " - ").concat(Setting.titleSuffix) : Setting.titleSuffix;
  if (count) fullTitle = "(".concat(count, "\u6761\u6D88\u606F)").concat(fullTitle);
  window.document.title = fullTitle;
};

function requestAnimation(task) {
  if ('requestAnimationFrame' in window) {
    return window.requestAnimationFrame(task);
  }

  setTimeout(task, 16);
}

export { requestAnimation };
export default util;