var pre = '/tool/';
export default {
  path: '/tool',
  title: '工具',
  header: 'home',
  custom: 'i-icon-demo i-icon-demo-tool',
  children: [{
    path: "".concat(pre, "frame"),
    title: '内嵌页面',
    children: [{
      path: "".concat(pre, "frame/iview"),
      title: 'View UI 官网'
    }, {
      path: "".concat(pre, "frame/iqiyi"),
      title: '爱奇艺'
    }]
  }, {
    path: "".concat(pre, "page-cache"),
    title: '页面缓存',
    children: [{
      path: "".concat(pre, "page-cache/on"),
      title: '开启缓存'
    }, {
      path: "".concat(pre, "page-cache/off"),
      title: '关闭缓存'
    }, {
      path: "".concat(pre, "page-cache/params/1"),
      title: '带参路由缓存1'
    }, {
      path: "".concat(pre, "page-cache/params/2"),
      title: '带参路由缓存2'
    }]
  }, {
    path: "".concat(pre, "log"),
    title: '日志',
    children: [{
      path: "".concat(pre, "log/record"),
      title: '日志记录'
    }, {
      path: "".concat(pre, "log/error"),
      title: '错误捕捉'
    }, {
      path: "".concat(pre, "log/ajax"),
      title: 'Ajax 错误'
    }, {
      path: "".concat(pre, "log/console"),
      title: '控制台日志'
    }]
  }]
};