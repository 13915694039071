var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "i-layout-header-trigger i-layout-header-trigger-min",
      on: { click: _vm.toggleFullscreen }
    },
    [
      _c("Icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isFullscreen,
            expression: "!isFullscreen"
          }
        ],
        attrs: { custom: "i-icon i-icon-full-screen" }
      }),
      _c("Icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isFullscreen,
            expression: "isFullscreen"
          }
        ],
        attrs: { custom: "i-icon i-icon-exit-full-screen" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }