function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { findComponentUpward } from 'view-design/src/utils/assist';
import { mapState } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('admin/layout', ['menuSiderReload', 'menuHeaderReload'])),
  methods: {
    handleClick: function handleClick(path) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'sider';
      var current = this.$route.path;

      if (current === path) {
        if (type === 'sider' && this.menuSiderReload) this.handleReload();else if (type === 'header' && this.menuHeaderReload) this.handleReload();
      }
    },
    handleReload: function handleReload() {
      var $layout = findComponentUpward(this, 'BasicLayout');
      if ($layout) $layout.handleReload();
    }
  }
};