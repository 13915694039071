var pre = '/list/';
export default {
  path: '/list',
  title: '列表页面',
  header: 'home',
  custom: 'i-icon-demo i-icon-demo-list',
  children: [{
    path: "".concat(pre, "basic-list"),
    title: '基础列表'
  }, {
    path: "".concat(pre, "card-list"),
    title: '卡片列表'
  }, {
    path: "".concat(pre, "user-list"),
    title: '用户列表'
  }, {
    path: "".concat(pre, "goods-list"),
    title: '商品列表'
  }, {
    path: "".concat(pre, "table-list"),
    title: '查询表格'
  }]
};