var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "i-layout-header-trigger i-layout-header-trigger-min" },
    [
      _c(
        "Dropdown",
        {
          staticClass: "i-layout-header-i18n",
          class: { "i-layout-header-user-mobile": _vm.isMobile },
          attrs: { trigger: _vm.isMobile ? "click" : "hover" },
          on: { "on-click": _vm.handleClick }
        },
        [
          _c("Icon", { attrs: { type: "md-globe" } }),
          _c(
            "DropdownMenu",
            { attrs: { slot: "list" }, slot: "list" },
            _vm._l(_vm.languages, function(item, key) {
              return _c(
                "DropdownItem",
                {
                  key: key,
                  attrs: { name: key, selected: _vm.locale === key }
                },
                [_c("span", [_vm._v(_vm._s(item.language))])]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }