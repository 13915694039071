var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "i-layout-menu-head-title" }, [
    (_vm.item.icon || _vm.item.custom || _vm.item.img) && !_vm.hideIcon
      ? _c(
          "span",
          { staticClass: "i-layout-menu-head-title-icon" },
          [
            _vm.item.icon
              ? _c("Icon", { attrs: { type: _vm.item.icon } })
              : _vm.item.custom
              ? _c("Icon", { attrs: { custom: _vm.item.custom } })
              : _vm.item.img
              ? _c("img", { attrs: { src: _vm.item.img } })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _c("span", { staticClass: "i-layout-menu-head-title-text" }, [
      _vm._v(_vm._s(_vm.tTitle(_vm.item.title)))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }