var pre = '/form/';
export default {
  path: '/form',
  title: '表单页面',
  header: 'home',
  custom: 'i-icon-demo i-icon-demo-form',
  children: [{
    path: "".concat(pre, "basic-form"),
    title: '基础表单'
  }, {
    path: "".concat(pre, "advanced-form"),
    title: '高级表单'
  }, {
    path: "".concat(pre, "step-form"),
    title: '分步表单'
  }, {
    path: "".concat(pre, "detail"),
    title: '表单详情'
  }]
};