function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { flattenSiderMenu } from '@/libs/system';
import iMenuHeadTitle from '../menu-head/title';
import { on, off } from 'view-design/src/utils/dom';
import { findComponentUpward, getStyle } from 'view-design/src/utils/assist';
import { throttle } from 'lodash';
export default {
  name: 'iHeaderBreadcrumb',
  components: {
    iMenuHeadTitle: iMenuHeadTitle
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('admin/layout', ['showBreadcrumbIcon', 'menuCollapse'])), mapState('admin/menu', ['openNames', 'activePath', 'header', 'headerName', 'menuSider'])), {}, {
    siderMenuObject: function siderMenuObject() {
      var obj = {};
      this.allSiderMenu.forEach(function (item) {
        if ('path' in item) {
          obj[item.path] = item;
        }
      });
      return obj;
    },
    items: function items() {
      var _this = this;

      var items = _toConsumableArray(this.openNames);

      var newItems = [];
      items.forEach(function (i) {
        newItems.push(_this.siderMenuObject[i]);
      });
      return newItems;
    },
    // 第一级，默认是 menu/header.js 中的第一项
    topItem: function topItem() {
      var _this2 = this;

      return this.header.find(function (item) {
        return item.name === _this2.headerName;
      });
    },
    // 得到所有侧边菜单，并转为平级，查询图标及显示对应内容
    allSiderMenu: function allSiderMenu() {
      return flattenSiderMenu(this.menuSider, []);
    }
  }),
  data: function data() {
    return {
      handleResize: function handleResize() {},
      isLimit: false,
      maxWidth: 560,
      breadcrumbWidth: 0
    };
  },
  methods: {
    handleCheckWidth: function handleCheckWidth() {
      var _this3 = this;

      var $header = findComponentUpward(this, 'Header');

      if ($header) {
        var headerWidth = parseInt(getStyle($header.$el, 'width'));
        this.$nextTick(function () {
          _this3.isLimit = headerWidth - _this3.maxWidth <= _this3.breadcrumbWidth;
        });
      }
    },
    handleGetWidth: function handleGetWidth() {
      var _this4 = this;

      this.isLimit = false;
      this.$nextTick(function () {
        var $breadcrumb = _this4.$refs.breadcrumb;

        if ($breadcrumb) {
          _this4.breadcrumbWidth = parseInt(getStyle($breadcrumb.$el, 'width'));
        }
      });
    }
  },
  watch: {
    topItem: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    },
    items: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    },
    activePath: {
      handler: function handler() {
        this.handleGetWidth();
        this.handleCheckWidth();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.handleResize = throttle(this.handleCheckWidth, 100, {
      leading: false
    });
    on(window, 'resize', this.handleResize);
    this.handleGetWidth();
    this.handleCheckWidth();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleResize);
  }
};