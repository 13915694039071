/**
 * 持久化存储
 * 一般情况下，您无需修改此文件
 * */
import util from '@/libs/util';
import router from '@/router';
import { cloneDeep } from 'lodash';
/**
 * @description 检查路径是否存在 不存在的话初始化
 * @param {Object} dbName {String} 数据库名称
 * @param {Object} path {String} 路径
 * @param {Object} user {Boolean} 区分用户
 * @param {Object} validator {Function} 数据校验钩子 返回 true 表示验证通过
 * @param {Object} defaultValue {*} 初始化默认值
 * @returns {String} 可以直接使用的路径
 */

function pathInit(_ref) {
  var _ref$dbName = _ref.dbName,
      dbName = _ref$dbName === void 0 ? 'database' : _ref$dbName,
      _ref$path = _ref.path,
      path = _ref$path === void 0 ? '' : _ref$path,
      _ref$user = _ref.user,
      user = _ref$user === void 0 ? true : _ref$user,
      _ref$validator = _ref.validator,
      validator = _ref$validator === void 0 ? function () {
    return true;
  } : _ref$validator,
      _ref$defaultValue = _ref.defaultValue,
      defaultValue = _ref$defaultValue === void 0 ? '' : _ref$defaultValue;
  var uuid = util.cookies.get('uuid') || 'ghost-uuid';
  var currentPath = "".concat(dbName, ".").concat(user ? "user.".concat(uuid) : 'public').concat(path ? ".".concat(path) : '');
  var value = util.db.get(currentPath).value();

  if (!(value !== undefined && validator(value))) {
    util.db.set(currentPath, defaultValue).write();
  }

  return currentPath;
}

export { pathInit };
export default {
  namespaced: true,
  actions: {
    /**
     * @description 将数据存储到指定位置 | 路径不存在会自动初始化
     * @description 效果类似于取值 dbName.path = value
     * @param context context
     * @param {Object} dbName {String} 数据库名称
     * @param {Object} path {String} 存储路径
     * @param {Object} value {*} 需要存储的值
     * @param {Object} user {Boolean} 是否区分用户
     */
    set: function set(context, _ref2) {
      var _ref2$dbName = _ref2.dbName,
          dbName = _ref2$dbName === void 0 ? 'database' : _ref2$dbName,
          _ref2$path = _ref2.path,
          path = _ref2$path === void 0 ? '' : _ref2$path,
          _ref2$value = _ref2.value,
          value = _ref2$value === void 0 ? '' : _ref2$value,
          _ref2$user = _ref2.user,
          user = _ref2$user === void 0 ? false : _ref2$user;
      util.db.set(pathInit({
        dbName: dbName,
        path: path,
        user: user
      }), value).write();
    },

    /**
     * @description 获取数据
     * @description 效果类似于取值 dbName.path || defaultValue
     * @param context context
     * @param {Object} dbName {String} 数据库名称
     * @param {Object} path {String} 存储路径
     * @param {Object} defaultValue {*} 取值失败的默认值
     * @param {Object} user {Boolean} 是否区分用户
     */
    get: function get(context, _ref3) {
      var _ref3$dbName = _ref3.dbName,
          dbName = _ref3$dbName === void 0 ? 'database' : _ref3$dbName,
          _ref3$path = _ref3.path,
          path = _ref3$path === void 0 ? '' : _ref3$path,
          _ref3$defaultValue = _ref3.defaultValue,
          defaultValue = _ref3$defaultValue === void 0 ? '' : _ref3$defaultValue,
          _ref3$user = _ref3.user,
          user = _ref3$user === void 0 ? false : _ref3$user;
      return new Promise(function (resolve) {
        resolve(cloneDeep(util.db.get(pathInit({
          dbName: dbName,
          path: path,
          user: user,
          defaultValue: defaultValue
        })).value()));
      });
    },

    /**
     * @description 获取存储数据库对象
     * @param {Object} context context
     * @param {Object} user {Boolean} 是否区分用户
     */
    database: function database(context) {
      var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref4$user = _ref4.user,
          user = _ref4$user === void 0 ? false : _ref4$user;

      return new Promise(function (resolve) {
        resolve(util.db.get(pathInit({
          dbName: 'database',
          path: '',
          user: user,
          defaultValue: {}
        })));
      });
    },

    /**
     * @description 清空存储数据库对象
     * @param {Object} context context
     * @param {Object} user {Boolean} 是否区分用户
     */
    databaseClear: function databaseClear(context) {
      var _ref5 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref5$user = _ref5.user,
          user = _ref5$user === void 0 ? false : _ref5$user;

      return new Promise(function (resolve) {
        resolve(util.db.get(pathInit({
          dbName: 'database',
          path: '',
          user: user,
          validator: function validator() {
            return false;
          },
          defaultValue: {}
        })));
      });
    },

    /**
     * @description 获取存储数据库对象 [ 区分页面 ]
     * @param {Object} context context
     * @param {Object} basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} user {Boolean} 是否区分用户
     */
    databasePage: function databasePage(context) {
      var _ref6 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref6$basis = _ref6.basis,
          basis = _ref6$basis === void 0 ? 'fullPath' : _ref6$basis,
          _ref6$user = _ref6.user,
          user = _ref6$user === void 0 ? false : _ref6$user;

      return new Promise(function (resolve) {
        resolve(util.db.get(pathInit({
          dbName: 'database',
          path: "$page.".concat(router.app.$route[basis]),
          user: user,
          defaultValue: {}
        })));
      });
    },

    /**
     * @description 清空存储数据库对象 [ 区分页面 ]
     * @param {Object} context context
     * @param {Object} basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} user {Boolean} 是否区分用户
     */
    databasePageClear: function databasePageClear(context) {
      var _ref7 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref7$basis = _ref7.basis,
          basis = _ref7$basis === void 0 ? 'fullPath' : _ref7$basis,
          _ref7$user = _ref7.user,
          user = _ref7$user === void 0 ? false : _ref7$user;

      return new Promise(function (resolve) {
        resolve(util.db.get(pathInit({
          dbName: 'database',
          path: "$page.".concat(router.app.$route[basis]),
          user: user,
          validator: function validator() {
            return false;
          },
          defaultValue: {}
        })));
      });
    },

    /**
     * @description 快速将页面当前的数据 ( $data ) 持久化
     * @param {Object} context context
     * @param {Object} instance {Object} vue 实例
     * @param {Object} basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} user {Boolean} 是否区分用户
     */
    pageSet: function pageSet(context, _ref8) {
      var instance = _ref8.instance,
          _ref8$basis = _ref8.basis,
          basis = _ref8$basis === void 0 ? 'fullPath' : _ref8$basis,
          _ref8$user = _ref8.user,
          user = _ref8$user === void 0 ? false : _ref8$user;
      return new Promise(function (resolve) {
        resolve(util.db.get(pathInit({
          dbName: 'database',
          path: "$page.".concat(router.app.$route[basis], ".$data"),
          user: user,
          validator: function validator() {
            return false;
          },
          defaultValue: cloneDeep(instance.$data)
        })));
      });
    },

    /**
     * @description 快速获取页面快速持久化的数据
     * @param {Object} context context
     * @param {Object} instance {Object} vue 实例
     * @param {Object} basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} user {Boolean} 是否区分用户
     */
    pageGet: function pageGet(context, _ref9) {
      var instance = _ref9.instance,
          _ref9$basis = _ref9.basis,
          basis = _ref9$basis === void 0 ? 'fullPath' : _ref9$basis,
          _ref9$user = _ref9.user,
          user = _ref9$user === void 0 ? false : _ref9$user;
      return new Promise(function (resolve) {
        resolve(cloneDeep(util.db.get(pathInit({
          dbName: 'database',
          path: "$page.".concat(router.app.$route[basis], ".$data"),
          user: user,
          defaultValue: cloneDeep(instance.$data)
        })).value()));
      });
    },

    /**
     * @description 清空页面快照
     * @param {Object} context context
     * @param {Object} basis {String} 页面区分依据 [ name | path | fullPath ]
     * @param {Object} user {Boolean} 是否区分用户
     */
    pageClear: function pageClear(context, _ref10) {
      var _ref10$basis = _ref10.basis,
          basis = _ref10$basis === void 0 ? 'fullPath' : _ref10$basis,
          _ref10$user = _ref10.user,
          user = _ref10$user === void 0 ? false : _ref10$user;
      return new Promise(function (resolve) {
        resolve(util.db.get(pathInit({
          dbName: 'database',
          path: "$page.".concat(router.app.$route[basis], ".$data"),
          user: user,
          validator: function validator() {
            return false;
          },
          defaultValue: {}
        })));
      });
    }
  }
};