var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "i-layout-header-trigger",
      class: { "i-layout-header-trigger-min": _vm.showReload },
      on: { click: _vm.handleToggleMenuSide }
    },
    [
      _c("Icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.menuCollapse || _vm.isMobile,
            expression: "menuCollapse || isMobile"
          }
        ],
        attrs: { custom: "i-icon i-icon-menu-unfold" }
      }),
      _c("Icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.menuCollapse && !_vm.isMobile,
            expression: "!menuCollapse && !isMobile"
          }
        ],
        attrs: { custom: "i-icon i-icon-menu-fold" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }