function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'system-';
export default {
  path: '/system',
  name: 'system',
  redirect: {
    name: "".concat(pre, "user")
  },
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'user',
    name: "".concat(pre, "user"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '用户管理'
    }),
    component: function component() {
      return import('@/pages/sys/user');
    }
  }, {
    path: 'resource',
    name: "".concat(pre, "resource"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '资源管理'
    }),
    component: function component() {
      return import('@/pages/sys/resource');
    }
  }, {
    path: 'role',
    name: "".concat(pre, "role"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '角色权限管理'
    }),
    component: function component() {
      return import('@/pages/sys/role');
    }
  }, {
    path: 'emailHistory',
    name: "".concat(pre, "emailHistory"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '邮件记录管理'
    }),
    component: function component() {
      return import('@/pages/sys/email/emailHistory');
    }
  }, {
    path: 'emailTemplate',
    name: "".concat(pre, "emailTemplate"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '邮件模版管理'
    }),
    component: function component() {
      return import('@/pages/sys/email/emailTemplate');
    }
  }, {
    path: 'baseDict',
    name: "".concat(pre, "baseDict"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '系统字典管理'
    }),
    component: function component() {
      return import('@/pages/sys/dict');
    }
  }, {
    path: 'log',
    name: "".concat(pre, "log"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '系统日志'
    }),
    component: function component() {
      return import('@/pages/sys/log');
    }
  }, {
    path: 'scheduled',
    name: "".concat(pre, "scheduled"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '调度任务'
    }),
    component: function component() {
      return import('@/pages/sys/scheduled');
    }
  }]
};