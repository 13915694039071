//
//
//
//
//
//
//
//
//
//
//
import mixinsLink from 'view-design/src/mixins/link';
export default {
  name: 'i-link',
  mixins: [mixinsLink],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    // 开启后，链接颜色为默认的蓝色，默认关闭为继承效果
    linkColor: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClickItem: function handleClickItem(event) {
      var new_window = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (this.disabled) {
        event.preventDefault();
        return;
      }

      this.handleCheckClick(event, new_window);
    }
  }
};