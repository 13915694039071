function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import BasicLayout from '@/layouts/basic-layout';
var meta = {
  auth: true
};
var pre = 'letter-';
export default {
  path: '/letter',
  name: 'letter',
  meta: meta,
  component: BasicLayout,
  children: [{
    path: 'plan/normDevice',
    name: "".concat(pre, "device-norm"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设备标准方案',
      planType: ['NORM', 'STANDARD_SPECIAL'],
      type: 'NORM',
      goodsType: 'DEVICE'
    }),
    component: function component() {
      return import('@/pages/custom/letter/plan/index');
    }
  }, {
    path: 'plan/specialDevice',
    name: "".concat(pre, "device-special"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设备特殊方案',
      type: 'SPECIAL',
      planType: ['SPECIAL'],
      goodsType: 'DEVICE'
    }),
    component: function component() {
      return import('@/pages/custom/letter/plan/index');
    }
  }, {
    path: 'plan/specialConsumable',
    name: "".concat(pre, "consumable-special"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '耗材特殊方案',
      type: 'SPECIAL',
      planType: ['SPECIAL'],
      goodsType: 'CONSUMABLES'
    }),
    component: function component() {
      return import('@/pages/custom/letter/plan/index');
    }
  }, {
    path: 'plan/normConsumable',
    name: "".concat(pre, "consumable-norm"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '耗材标准方案',
      type: 'NORM',
      planType: ['NORM', 'STANDARD_SPECIAL'],
      goodsType: 'CONSUMABLES'
    }),
    component: function component() {
      return import('@/pages/custom/letter/plan/index');
    }
  }, {
    path: 'plan/deviceDetail',
    name: "".concat(pre, "plan-deviceDetail"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '设备方案配置',
      auth: false,
      active: false,
      needMenu: true
    }),
    component: function component() {
      return import('@/pages/custom/letter/plan/device-detail.vue');
    }
  }, {
    path: 'plan/consumableDetail',
    name: "".concat(pre, "plan-consumableDetail"),
    meta: _objectSpread(_objectSpread({}, meta), {}, {
      title: '耗材方案配置',
      auth: false,
      active: false,
      needMenu: true
    }),
    component: function component() {
      return import('@/pages/custom/letter/plan/consumable-detail.vue');
    }
  }]
};