import request from '@/plugins/request';
export function menu() {
  return request({
    url: '/erp/system/resource/menu',
    method: 'get'
  });
}
export function menuTreeAll() {
  return request({
    url: '/erp/system/resource/menuTreeAll',
    method: 'get'
  });
}
export function treeWithRole(roleId) {
  return request({
    url: '/erp/system/resource/treeWithRole?roleId=' + roleId,
    method: 'get'
  });
}
export function getAuthButton(parentId) {
  return request({
    url: '/erp/system/resource/getAuthButton?parentId=' + parentId,
    method: 'get'
  });
}
export function save(data) {
  return request({
    url: '/erp/system/resource/save',
    method: 'post',
    data: data
  });
}
export function saveRoleWithResource(data) {
  return request({
    url: '/erp/system/resource/saveRoleWithResource',
    method: 'post',
    data: data
  });
}
export function del(id) {
  return request({
    url: '/erp/system/resource/del?id=' + id,
    method: 'post'
  });
}