function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
import { on, off } from 'view-design/src/utils/dom';
import { setMatchMedia } from 'view-design/src/utils/assist';
import { mapMutations } from 'vuex';
setMatchMedia();
export default {
  name: 'app',
  methods: _objectSpread(_objectSpread({}, mapMutations('admin/layout', ['setDevice'])), {}, {
    handleWindowResize: function handleWindowResize() {
      this.handleMatchMedia();
    },
    handleMatchMedia: function handleMatchMedia() {
      var matchMedia = window.matchMedia;

      if (matchMedia('(max-width: 600px)').matches) {
        this.setDevice('Mobile');
      } else if (matchMedia('(max-width: 992px)').matches) {
        this.setDevice('Tablet');
      } else {
        this.setDevice('Desktop');
      }
    }
  }),
  mounted: function mounted() {
    on(window, 'resize', this.handleWindowResize);
    this.handleMatchMedia();
  },
  beforeDestroy: function beforeDestroy() {
    off(window, 'resize', this.handleWindowResize);
  }
};