var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Tooltip",
    { attrs: { content: _vm.tooltipContent, transfer: "" } },
    [
      _c(
        "span",
        {
          staticClass: "i-layout-header-trigger i-layout-header-trigger-min",
          on: { click: _vm.handleOpenLog }
        },
        [
          _c(
            "Badge",
            {
              attrs: {
                count: _vm.lengthError === 0 ? null : _vm.lengthError,
                "overflow-count": 99,
                dot: _vm.showDot,
                offset: _vm.showDot ? [26, 2] : [20, 0]
              }
            },
            [_c("Icon", { attrs: { custom: "i-icon i-icon-record" } })],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }