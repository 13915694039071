// 生成随机字符串
export default function () {
  var len = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 32;
  var $chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
  var maxPos = $chars.length;
  var str = '';

  for (var i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxPos));
  }

  return str;
}