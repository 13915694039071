var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "i-layout-header-trigger i-layout-header-trigger-min" },
    [
      _c(
        "Dropdown",
        {
          staticClass: "i-layout-header-user",
          class: { "i-layout-header-user-mobile": _vm.isMobile },
          attrs: { trigger: _vm.isMobile ? "click" : "hover" },
          on: { "on-click": _vm.handleClick }
        },
        [
          _vm.info.avatar
            ? _c("Avatar", { attrs: { size: "small", src: _vm.info.avatar } })
            : _c("Avatar", { attrs: { size: "small" } }, [
                _vm._v(_vm._s(_vm.info.lastName))
              ]),
          !_vm.isMobile
            ? _c("span", { staticClass: "i-layout-header-user-name" }, [
                _vm._v(_vm._s(_vm.info.lastName) + _vm._s(_vm.info.firstName))
              ])
            : _vm._e(),
          _c(
            "DropdownMenu",
            { attrs: { slot: "list" }, slot: "list" },
            [
              _c(
                "i-link",
                { attrs: { to: "/setting/user" } },
                [
                  _c(
                    "DropdownItem",
                    [
                      _c("Icon", { attrs: { type: "ios-contact-outline" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("basicLayout.user.center")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "i-link",
                { attrs: { to: "/setting/account" } },
                [
                  _c(
                    "DropdownItem",
                    [
                      _c("Icon", { attrs: { type: "ios-settings-outline" } }),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("basicLayout.user.setting")))
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "DropdownItem",
                { attrs: { divided: "", name: "logout" } },
                [
                  _c("Icon", { attrs: { type: "ios-log-out" } }),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("basicLayout.user.logOut")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }