export default {
  locale: 'zh-CN',
  language: '简体中文',
  menu: {
    i18n: '多语言'
  },
  system: {
    hmTableError: 'hm-table组件渲染错误',
    pleaseEnter: '请输入 ',
    pleaseChoose: '请选择',
    reUploadFile: '重新上传',
    uploadFile: '选择文件',
    uploadFileTip: '支持上传png jpeg格式图片',
    dataLose: '参数缺失',
    editTip: '请选择要编辑的行',
    delTip: '请选中或者选择要删除的行',
    delConfirm: '确认要删除吗？',
    delConfirmContent: '确认要删除所选的<strong>{count}</strong>条数据吗？',
    delSuccess: '删除成功',
    formCheckError: '表单校验失败！',
    exportName: '数据表'
  },
  common: {
    search: '查询',
    reset: '重置',
    unfold: '展开',
    packUp: '收起',
    submit: '提交',
    submitSuccess: '提交成功',
    confirmTitle: '确认操作！',
    all: '全部',
    tableSize: '密度',
    default: '默认',
    large: '宽松',
    small: '紧凑',
    fullscreen: '全屏',
    outFullscreen: '退出全屏',
    refresh: '刷新',
    add: '新增',
    edit: '编辑',
    del: '删除',
    type: '类型',
    title: '标题',
    enTitle: '英文标题',
    selectColumns: '显示字段'
  },
  status: {
    normal: '正常',
    lock: '锁定',
    deleted: '删除',
    disable: '禁用'
  },
  page: {
    login: {
      title: '登录',
      remember: '自动登录',
      forgot: '忘记密码',
      submit: '登录',
      other: '其它登录方式',
      signup: '注册账户'
    },
    register: {
      title: '注册',
      submit: '注册',
      other: '使用已有账户登录'
    },
    exception: {
      e403: '抱歉，你无权访问该页面',
      e404: '抱歉，你访问的页面不存在',
      e500: '抱歉，服务器出错了',
      btn: '返回首页'
    },
    i18n: {
      content: '你好，很高兴认识你！'
    },
    system: {
      user: {
        setRole: '设置角色',
        tip: {
          handleSetRole: '请选中要设置角色的行',
          resetPassword: '请选中要重制密码的行',
          restPasswordConfirm: '确认要重置用户：{username} 的密码吗？',
          resetPasswordSuccess: '重置密码成功',
          newPassword: '新密码：'
        }
      },
      resource: {
        add: '添加菜单',
        allZk: '全部展开',
        allSq: '全部收起',
        searchTip: '输入菜单名称搜索',
        addChild: '添加子菜单',
        editChildTip: '从菜单列表选择一项后，进行编辑',
        menu: '菜单',
        button: '按钮',
        titleTip: '请输入标题，必填',
        enTitleTip: '请输入英文标题',
        codeTip: '请输入code，必填',
        path: '路径',
        method: '方法',
        pathTip: '请输入路径，必填',
        buttonType: '按钮类型',
        buttonSize: '按钮大小',
        sort: '排序',
        sortTip: '请输入排序，数字越小越靠前',
        icon: '图标',
        iconTip: '请选择图标',
        save: '保存修改',
        closeTip: '关闭确认',
        closeTip1: '您已经编辑了菜单信息，是否直接关闭？',
        delTip: '删除确认',
        delTip1: '您确认要删除菜单<strong>{title}</strong>吗？'
      },
      role: {
        buttonResource: '资源权限',
        resourceTip: '编辑资源权限',
        buttonDataAuth: '数据权限'
      },
      tenant: {
        defaultUserTip: '租户创建完成后，系统将自动分配一个用户。默认用户名：当前租户编号，默认密码：Password123'
      }
    }
  },
  router: {
    setting: {
      center: '个人中心',
      setting: '个人设置'
    },
    system: {
      user: '用户管理',
      resource: '资源管理',
      role: '角色权限管理',
      emailHistory: '邮件记录管理',
      emailTemplate: '邮件模版管理',
      baseDict: '系统字典管理',
      tenant: '租户管理'
    }
  }
};