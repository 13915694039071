var pre = '/search/';
export default {
  path: '/search',
  title: '搜索页面',
  header: 'home',
  custom: 'i-icon-demo i-icon-demo-search',
  children: [{
    path: "".concat(pre, "search-article"),
    title: '搜索列表（文章）'
  }, {
    path: "".concat(pre, "search-projects"),
    title: '搜索列表（项目）'
  }, {
    path: "".concat(pre, "search-apps"),
    title: '搜索列表（应用）'
  }]
};