var pre = '/exception/';
export default {
  path: '/exception',
  title: '异常页面',
  header: 'home',
  custom: 'i-icon-demo i-icon-demo-exception',
  children: [{
    path: "".concat(pre, "403"),
    title: '403'
  }, {
    path: "".concat(pre, "404"),
    title: '404'
  }, {
    path: "".concat(pre, "500"),
    title: '500'
  }]
};