var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Submenu",
    { attrs: { name: _vm.menu.path } },
    [
      _c(
        "template",
        { slot: "title" },
        [
          _c("i-menu-side-title", { attrs: { menu: _vm.menu } }),
          _vm.badgeData
            ? _c(
                "Badge",
                _vm._b(
                  { staticClass: "i-layout-menu-side-badge" },
                  "Badge",
                  _vm.badgeData,
                  false
                )
              )
            : _vm._e()
        ],
        1
      ),
      _vm._l(_vm.menu.children, function(item, index) {
        return [
          item.children === undefined || !item.children.length
            ? _c("i-menu-side-item", { key: index, attrs: { menu: item } })
            : _c("i-menu-side-submenu", { key: index, attrs: { menu: item } })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }